import React from 'react'
import { Accordion } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import TdLink from './TdLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { MDContent } from './Content'

const Faq = ({ faqs }) => {

    const { formatMessage: f } = useIntl()

    return (
        <section className="pt-6 mb-5 mb-md-0">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="text-center mb-4 mb-md-6">
                            <h2 className="fw-bold mb-0">
                                {f({ id: 'faq_title' })}
                            </h2>
                        </div>
                        <Accordion className="card shadow-light-lg">
                            {faqs.map((faq, index) => (
                                <Accordion.Item key={index} eventKey={index.toString()}>
                                    <Accordion.Header className="d-flex">
                                        <span className='me-4'>{faq.title}</span>
                                        <div className="text-muted ms-auto">
                                            <span className="collapse-chevron text-muted">
                                                <FontAwesomeIcon icon={faChevronDown} size="xs" />
                                            </span>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body className="text-muted-half"><MDContent content={faq.faq_content} /></Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                        <div className="text-center mt-5 font-size-lg">
                            <TdLink to="/about/faq">
                                {f({ id: 'faq_viewMore' })}
                            </TdLink>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default Faq