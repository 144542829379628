import React from 'react'

import templewallet from '../assets/img/wallets/templewallet.svg'
import airgapwallet from '../assets/img/wallets/airgap.svg'
import kukaiwallet from '../assets/img/wallets/kukai.svg'
import spirewallet from '../assets/img/wallets/spire.svg'
import ledgerwallet from '../assets/img/wallets/ledger.svg'
import galleonwallet from '../assets/img/wallets/galleon.png'
import tzstatswallet from '../assets/img/wallets/tzstats.svg'
import taquitowallet from '../assets/img/wallets/taquito.png'
import tezblockwallet from '../assets/img/wallets/tezblock.svg'
import beaconwallet from '../assets/img/wallets/beacon.svg'
import bakingbadwallet from '../assets/img/wallets/bakingbad.svg'
import tzktwallet from '../assets/img/wallets/tzkt.svg'


const Testimonials = ({ testimonialsTexts }) => {
    return (
        <section className="pt-8 pt-md-11">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8 text-center">

                        <h2 className="fw-bold">
                            {testimonialsTexts.title}
                        </h2>

                        <p className="text-muted mb-7 mb-md-9">
                            {testimonialsTexts.description}
                        </p>

                    </div>
                </div>
                <div className="row justify-content-center logos">
                    <div className="col-6 col-lg-2">
                        <a href="https://templewallet.com" target="_blank" rel="noreferrer">
                            <div className="mb-8 mt-n4 d-flex justify-content-center">
                                <img src={templewallet} alt="Temple Wallet" style={{ height: '2.25em' }} />
                            </div>
                        </a>
                    </div>
                    <div className="col-6 col-lg-2">
                        <a href="https://airgap.it" target="_blank" rel="noreferrer">
                            <div className="mb-8 mt-n2 d-flex justify-content-center">
                                <img src={airgapwallet} alt="Temple Wallet" style={{ height: '1.8em' }} />
                            </div>
                        </a>
                    </div>
                    <div className="col-6 col-lg-2">
                        <a href="https://kukai.app" target="_blank" rel="noreferrer">
                            <div className="mb-8 mt-n2 d-flex justify-content-center">
                                <img src={kukaiwallet} alt="Kukai" style={{ height: '1.5em' }} />
                            </div>
                        </a>
                    </div>
                    <div className="col-6 col-lg-2">
                        <div className="mb-8 mt-n4 d-flex justify-content-center">
                            <img src={spirewallet} alt="Spire" style={{ height: '2.25em' }} />
                        </div>
                    </div>
                    <div className="col-6 col-lg-2">
                        <a href="https://www.ledger.com" target="_blank" rel="noreferrer">
                            <div className="mb-8 mt-n2 d-flex justify-content-center">
                                <img src={ledgerwallet} alt="Ledger" style={{ height: '1.8em' }} />
                            </div>
                        </a>
                    </div>
                    <div className="col-6 col-lg-2">
                        <a href="https://cryptonomic.tech/galleon.html" target="_blank" rel="noreferrer">
                            <div className="mb-8 mt-n3 d-flex justify-content-center">
                                <img src={galleonwallet} alt="Galleon" style={{ height: '3em' }} className="me-3 mt-n2" />

                                <h3 className="align-self-center text-dark">Galleon</h3>
                            </div>
                        </a>
                    </div>
                    <div className="col-6 col-lg-2">
                        <a href="https://tzstats.com" target="_blank" rel="noreferrer">
                            <div className="mb-6 mt-n4 d-flex justify-content-center">
                                <img src={tzstatswallet} alt="TzStats" style={{ height: '3em' }} />
                            </div>
                        </a>
                    </div>
                    <div className="col-6 col-lg-2">
                        <a href="https://tezostaquito.io" target="_blank" rel="noreferrer">
                            <div className="mb-6 mt-n2 d-flex justify-content-center">
                                <img src={taquitowallet} alt="Taquito" style={{ height: '2em' }} />
                            </div>
                        </a>
                    </div>
                    <div className="col-6 col-lg-2">
                        <a href="https://tezblock.io" target="_blank" rel="noreferrer">
                            <div className="mb-7 mt-n2 d-flex justify-content-center">
                                <img src={tezblockwallet} alt="TezBlock" style={{ height: '2em' }} />
                            </div>
                        </a>
                    </div>
                    <div className="col-6 col-lg-2">
                        <a href="https://www.walletbeacon.io" target="_blank" rel="noreferrer">
                            <div className="mb-7 mt-n2 d-flex svg-shim justify-content-center">
                                <img src={beaconwallet} alt="Beacon" style={{ height: '2.25em' }} />
                            </div>
                        </a>
                    </div>
                    <div className="col-6 col-lg-2">
                        <a href="https://baking-bad.org" target="_blank" rel="noreferrer">
                            <div className="mb-8 mt-n1 me-n3 d-flex justify-content-center">
                                <img src={bakingbadwallet} alt="Baking Bad" style={{ height: '1.5em' }} className="me-2 mb-2" />
                                <h3 className="align-self-center text-dark">Baking&nbsp;Bad</h3>
                            </div>
                        </a>
                    </div>
                    <div className="col-6 col-lg-2">
                        <a href="https://tzkt.io" target="_blank" rel="noreferrer">
                            <div className="mb-8 mt-n1 ms-2 d-flex justify-content-center">
                                <img src={tzktwallet} alt="TzKT" style={{ height: '1.5em' }} className="me-2 mb-2" />
                                <h3 className="align-self-center text-dark">TzKT</h3>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonials