import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Testimonials from '../components/Testimonials'
import Faq from '../components/Faq'

import { HTMLContent } from '../components/Content'
import Search from '../components/Search'
import { ReactComponent as Blur } from '../assets/img/shapes/blurs/blur-2.svg'
import { ReactComponent as DoubleCheck } from '../assets/img/icons/duotone-icons/Navigation/Double-check.svg'
import { ReactComponent as Exchange } from '../assets/img/icons/duotone-icons/Navigation/Exchange.svg'
import { ReactComponent as ChatCheck } from '../assets/img/icons/duotone-icons/Communication/Chat-check.svg'
import { ReactComponent as Wallet } from '../assets/img/icons/duotone-icons/Shopping/Wallet.svg'
import { ReactComponent as ShieldUser } from '../assets/img/icons/duotone-icons/Communication/Shield-user.svg'
import { ReactComponent as Git } from '../assets/img/icons/duotone-icons/Code/Git-4.svg'
import { ReactComponent as AddressBook } from '../assets/img/icons/duotone-icons/Communication/Adress-book-2.svg'
import { ReactComponent as Globe } from '../assets/img/icons/duotone-icons/Home/Globe.svg'

import illustration from '../assets/img/illustrations/illustration-4.png'
import illustration2 from '../assets/img/illustrations/illustration-9.png'

export const IndexPageTemplate = ({ data, lang }) => {
  const { headline, subheadline, mainpoints, searchexample, secondarypoints, yourspace, testimonials, faqs } = data

  const headlineClass = lang === 'ru' || lang === 'ja' ? 'display-3 mt-n5' : 'display-2 fw-bold mt-n5'

  return (
    <div>
      <section className="position-relative pt-14 mt-n11">
        <div className="shape shape-fluid-x shape-blur-2 svg-shim text-light">
          <Blur />
        </div>

        <div className="container">
          <div className="row align-items-center text-md-left">
            <div className="col-12 col-md-6">
              <img src={illustration} alt="..." className="img-fluid mw-130 float-end mb-6 mb-md-0" data-aos="fade-right" />
            </div>
            <div className="col-12 col-md-6">
              <h1 className={headlineClass}>
                <div data-aos="fade-left">{headline}</div>
                <div data-aos="fade-left" data-aos-delay="100">
                  {subheadline}
                </div>
              </h1>

              <div className="fs-lg text-muted mb-0" data-aos="fade-left" data-aos-delay="200">
                <HTMLContent content={searchexample} />
              </div>
              <Search />
            </div>
          </div>
        </div>
      </section>
      <section className="py-8 py-md-11">
        <div className="container">
          {
            <div className="row">
              <div className="col-12 col-md-4 text-center" data-aos="fade-up">
                <div className="icon text-primary mb-3">
                  <DoubleCheck viewBox="0 0 24 24" />
                </div>

                <h3>{mainpoints[0].title}</h3>

                <div className="text-muted mb-6 ">
                  <HTMLContent content={mainpoints[0].text} />
                </div>
              </div>
              <div className="col-12 col-md-4 text-center" data-aos="fade-up" data-aos-delay="50">
                <div className="icon text-primary mb-3">
                  <Exchange viewBox="0 0 24 24" />
                </div>

                <h3>{mainpoints[1].title}</h3>

                <div className="text-muted mb-6 ">
                  <HTMLContent content={mainpoints[1].text} />
                </div>
              </div>
              <div className="col-12 col-md-4 text-center" data-aos="fade-up" data-aos-delay="100">
                <div className="icon text-primary mb-3">
                  <ChatCheck viewBox="0 0 24 24" />
                </div>

                <h3>{mainpoints[2].title}</h3>

                <div className="text-muted mb-6 ">
                  <HTMLContent content={mainpoints[2].text} />
                </div>
              </div>
              <div className="col-0 col-md-1"></div>
              <div className="col-12 col-md-5 text-center" data-aos="fade-up" data-aos-delay="100">
                <div className="icon text-primary mb-3">
                  <Wallet viewBox="0 0 24 24" />
                </div>

                <h3>{mainpoints[3].title}</h3>

                <div className="text-muted mb-6 ">
                  <HTMLContent content={mainpoints[3].text} />
                </div>
              </div>
              <div className="col-12 col-md-5 text-center" data-aos="fade-up" data-aos-delay="150">
                <div className="icon text-primary mb-3">
                  <ShieldUser viewBox="0 0 24 24" />
                </div>

                <h3>{mainpoints[4].title}</h3>

                <div className="text-muted mb-6 ">
                  <HTMLContent content={mainpoints[4].text} />
                </div>
              </div>
              <div className="col-0 col-md-1"></div>
            </div>
          }
        </div>
      </section>

      <section className="py-7 py-md-9 bg-gray-200">
        <div className="container">
          <div className="row justify-content-between align-items-center mb-5">
            <div className="col-12 col-md-4 order-md-2 text-center">
              <img className="img-fluid w-75 w-md-100 mb-6 mb-md-0" src={illustration2} alt="..." />
            </div>
            <div className="col-12 col-md-7 order-md-1">
              <h2>{yourspace.title}</h2>

              <div className="lead text-muted-half mb-0">
                <HTMLContent content={yourspace.description} />
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-12 col-lg-6 d-flex pb-1 mb-4">
              <div className="card shadow-light-lg overflow-hidden" data-aos="fade-up">
                <div className="card-body py-3 py-md-5 text-center">
                  <div className="icon-lg text-primary mb-3">
                    <Git viewBox="0 0 24 24" />
                  </div>

                  <h4 className="fw-bold">{secondarypoints[0].title}</h4>

                  <div className="text-muted mb-0">
                    <HTMLContent content={secondarypoints[0].description} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 d-flex pb-1 mb-4">
              <div className="card shadow-light-lg overflow-hidden text-center" data-aos="fade-up">
                <div className="card-body py-3 py-md-5 text-center">
                  <div className="icon-lg text-primary mb-3">
                    <AddressBook viewBox="0 0 24 24" />
                  </div>

                  <h4 className="fw-bold">{secondarypoints[1].title}</h4>

                  <div className="text-muted mb-0">
                    <HTMLContent content={secondarypoints[1].description} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 pb-1 mb-4">
              <div className="card shadow-light-lg overflow-hidden" data-aos="fade-up">
                <div className="card-body py-3 py-md-5 text-center">
                  <div className="icon-lg text-primary mb-3">
                    <Globe viewBox="0 0 24 24" />
                  </div>
                  <h4 className="fw-bold">{secondarypoints[2].title}</h4>
                  <div className="text-muted mb-0">{secondarypoints[2].description}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonials testimonialsTexts={testimonials} />
      <Faq faqs={faqs} />
    </div>
  )
}

IndexPageTemplate.propTypes = {
  data: PropTypes.object,
}

const IndexPage = ({ data }) => {
  const { frontmatter, fields } = data.markdownRemark

  return (
    <Layout lang={{ slug: fields.slug, langKey: fields.langKey }} frontmatter={frontmatter}>
      <IndexPageTemplate data={frontmatter} lang={fields.langKey} />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
  lang: PropTypes.string,
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
        langKey
      }
      frontmatter {
        headline
        subheadline
        searchexample
        yourspace {
          description
          title
        }
        mainpoints {
          title
          text
        }
        secondarypoints {
          title
          description
        }
        testimonials {
          title
          description
        }
        faqs {
          title
          faq_content
        }
      }
    }
  }
`
