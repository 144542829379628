import React from 'react'
import { FormattedMessage } from 'react-intl'
import useSiteMetadata from './SiteMetadata';

const Search = () => {
    const { appUrl } = useSiteMetadata();

    return (<form
        className="rounded shadow mt-5 mb-6 no-prelaunch"
        method="GET"
        autoComplete="off"
        autoCorrect="off"
        action={`${appUrl}/search`}
        target="_blank"
    >
        <div className="input-group">
            <span className="input-group-text border-0 pe-1">
                <i className="fe fe-search"></i>
            </span>

            <input className="form-control border-0 px-1" name="q" />

            <span className="input-group-text border-0 py-0 ps-1 pe-3">
                <button type="submit" className="btn btn-sm btn-primary">
                    <FormattedMessage id="index_search"></FormattedMessage>
                </button>
            </span>
        </div>
    </form>)
}

export default Search